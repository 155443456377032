import {
  SAVE_CUSTOM_CATEGORIES,
  SAVE_CUSTOM_CATEGORY,
  SAVE_PREDEFINED_CATEGORIES,
  DELETE_CUSTOM_CATEGORY,
} from "./category.constants";

const INITIAL_STATE = {
  customCategories: {},
  categories: {},
};

const categoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_CUSTOM_CATEGORIES: {
      return {
        ...state,
        customCategories: action.payload.data,
      };
    }
    case SAVE_PREDEFINED_CATEGORIES: {
      return {
        ...state,
        categories: action.payload.data,
      };
    }
    case SAVE_CUSTOM_CATEGORY: {
      const newCategory = action.payload.data;
      const existingCat = { ...state.customCategories };

      const names = Object.values(existingCat.name);
      names.push(newCategory.name);
      existingCat.name = { ...names };

      const items = Object.values(existingCat.items);
      items.push(newCategory.items);
      existingCat.items = { ...items };

      const date_created = Object.values(existingCat.date_created);
      date_created.push(newCategory.date_created);
      existingCat.date_created = { ...date_created };

      return {
        ...state,
        customCategories: existingCat,
      };
    }
    case DELETE_CUSTOM_CATEGORY: {
        const removeCategory = action.payload.categoryName;
        const existingCat = { ...state.customCategories };

        let index = null;
        Object.values(existingCat.name).forEach((name, i) => {
            if(name === removeCategory) {
                index = i;
                return false;
            }
        });

        const names = Object.values(existingCat.name).filter((name, i) => i !== index);
        existingCat.name = { ...names };

        const items = Object.values(existingCat.items).filter((item, i) => i !== index);
        existingCat.items = { ...items };

        const date_created = Object.values(existingCat.date_created).filter((dc, i) => i !== index);
        existingCat.date_created = { ...date_created };

        return {
            ...state,
            customCategories: existingCat,
        };
    }
    default: {
      return state;
    }
  }
};

export default categoryReducer;
