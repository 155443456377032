import React, { Component } from 'react';
import { GoogleLogout } from 'react-google-login';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { Button, InputLabel, MenuItem, FormControl, Select, Grid, Slide, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import PropTypes from "prop-types";
import { compose } from 'redux';

import { withStyles } from "@material-ui/core/styles";
import Cookies from 'universal-cookie';
import styles from './Profile.module.css';
import { SET_LOADER, SET_MARKET_CONFIG, SET_TOAST, SET_TOAST_FLAG } from '../../redux/mainApp/mainApp.actions';
import { decode_json_for_escapeChar } from '../../utils/helper';
import { SET_DYNAMIC_MAPS } from '../../redux/filterBar/filterBar.actions';


const cookies = new Cookies();

// const config = require(process.env.REACT_APP_MARKET_CONFIG);
let clientId = "";
if (process.env.REACT_APP_CLIENT_ID) {
  clientId = process.env.REACT_APP_CLIENT_ID;
}
const CustomLogoutButton = withStyles({
  root: {
    backgroundColor: 'rgb(255, 255, 255)',
    display: 'inline - flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px',
    // padding: 0,
    borderRadius: '2px',
    border: '1px solid transparent',
    fontSize: '14px',
    fontWeight: 500,
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#FFF",

    }
  },

  label: {
    textTransform: 'capitalize',
  },
})(Button);
const CustomFormControl = withStyles({
  root: {
    width: "90%",
    margin: "-8px 5px",
    textAlign: "center"
  }
})(FormControl);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// styling for slider
const style = theme => ({
  button: {
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    background: '#FFBC0D',
    fontFamily: 'Speedee_W_Rg',
    "&:hover": {
      backgroundColor: "#ffcf50"
    },
  },

  cancelButton: {
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    background: '#e0e0e0',
    fontFamily: 'Speedee_W_Rg',
    "&:hover": {
      backgroundColor: "#e0e0e0"
    }
  },
  disabledButton: {
    background: 'lightgrey',
    margin: 'auto',
    fontFamily: 'Speedee_W_Rg',
  },
  dialogSegmentName: {
    fontFamily: 'Speedee_W_Rg',
    fontWeight: 'bold',
    color: '#292929'
  },

  dialog: {
    maxWidth: '500px',
    display: 'block',
    margin: 'auto'
  },
  dialogActions: {
    display: 'block',
    margin: 'auto'
  },
  dialogContent: {
    minWidth: '180px',
    minHeight: '60px',
    textAlign: 'center',
    padding: '0px'
  },
  dialogPaper: {
    padding: '4%'
  },

});

class Profile extends Component {
  state = {
    openAlert: false,
    selectedMarket: "",
    activeMarketChosen: this.props.currentMarket,
    userMarketOptions: JSON.parse(sessionStorage.getItem("authorized_markets")) || []
  }
  logout = (response) => {
    const config = JSON.parse(sessionStorage.getItem("marketConfig"));
    //Fixing caching issue so that once login with mcd email id and after logout no other google id is allowed to login
    if (config.frontendUrl && window.location.origin !== "http://localhost:3000") {
      fetch(`${config.frontendUrl}/_gcp_iap/clear_login_cookie`)
        .then(res => {
          console.log("logging out");
          window.location.reload()
        }).
        catch(err => window.location.reload());
    } else {
      window.location.reload();
    }
  }
  awsLogoutHandler = () => {
    cookies.remove("token")
    cookies.remove("name")
    window.location.reload();
  }
  createInitials = () => {
    let nameArr = this.props.username.split(" ");
    let lastInitial = nameArr.length > 1 ? nameArr[1][0].toUpperCase() : ''
    return nameArr[0][0].toUpperCase() + lastInitial;
  }
  handleAlert = (e, val) => {
    if (e.target.value && val) {
      this.setState({ selectedMarket: e.target.value })
    }
    this.setState({ openAlert: val })
  }

  handleMarketChange = (e) => {
    this.setState({ openAlert: false });
    sessionStorage.setItem("selectedMarket", this.state.selectedMarket);
    sessionStorage.removeItem("marketConfig");
    window.location.reload()
    //changeMarket method not required for now as we are reloading the app
    // this.changeMarket(e, this.state.selectedMarket)
  }
  //changeMarket method not required for now as we are reloading the app

  // changeMarket = (e, selectedMarketName) => {
  //   let previousMarket = this.state.activeMarketChosen;
  //   //to show alert popup here before changing market
  //   this.setState({ activeMarketChosen: selectedMarketName })
  //   this.props.setLoader(true);
  //   let prevMarketConfig = JSON.parse(sessionStorage.getItem("marketConfig"))

  //   sessionStorage.removeItem("marketConfig")

  //   fetch(`${this.props.baseURL}/api/getUIMetaConfig/${selectedMarketName}`, {
  //     method: "GET",
  //     headers: { Accept: "application/json", Authorization: "Bearer " + this.props.token, currentMarket: selectedMarketName },
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json()
  //       } else {
  //         throw { error: "Something went wrong", ok: response.ok, status: response.status, redirected: response.redirected, statusText: response.statusText, type: response.type, url: response.url }

  //       }
  //     })
  //     .then((response) => {
  //       console.log("Market Configurations", response);
  //       this.props.setLoader(false)
  //       if (response.status === -1) {
  //         this.props.setToastFlag(true)
  //         this.props.setToast({
  //           msg: response.message + ": Please select another market",
  //           duration: 4000,
  //           severity: "error"
  //         })
  //         this.setState({ activeMarketChosen: previousMarket })
  //       } else {
  //         let reslt = decode_json_for_escapeChar(response);
  //         this.props.setMarketConfig("marketConfig", reslt)
  //         this.props.setMarketConfig("currentMarket", reslt.market)
  //         this.props.setMarketMaps("geoLevel1Multiselect", reslt.geoLevel1List)
  //         if (response.geoLevel2List) {
  //           this.props.setMarketMaps("geoLevel2Multiselect", reslt.geoLevel2List)
  //         }
  //         sessionStorage.setItem("current_market", reslt.market);
  //         sessionStorage.setItem("marketConfig", JSON.stringify(reslt))
  //         this.props.handleChange(e, 0)
  //       }

  //       //once it redirect to data management tab call the API to load that market data
  //     })
  //     .catch(error => {
  //       this.props.setLoader(false)
  //       console.log("Changing market configuration error: ", error);
  //       this.props.setToastFlag(true)
  //       this.props.setToast({
  //         msg: error.error || "Something went wrong",
  //         duration: 4000,
  //         severity: "error"
  //       })
  //       this.setState({ activeMarketChosen: previousMarket })
  //     });

  // }

  render() {
    let avatar = null
    let logoutBtn = null
    let userMarkets = null
    const { classes } = this.props;
    if (process.env.REACT_APP_PLATFORM === "AWS") {
      userMarkets = <div id="userWrapper" className={styles.userWrapper}>
        <CustomFormControl className={`${styles.formControl}`}>
          <InputLabel id="market-select-label">Markets</InputLabel>
          <Select
            labelId="market-select-label"
            id="market-select"
            value={this.state.activeMarketChosen}
            onChange={(e) => this.handleAlert(e, true)}
          >
            {Object.keys(this.state.userMarketOptions).map((markt_level) => (
              <MenuItem key={`cat-${markt_level}`} value={markt_level}>
                {this.state.userMarketOptions[markt_level].label}
              </MenuItem>
            ))}
          </Select>
        </CustomFormControl>
      </div >
      avatar = <Avatar className={styles.profileImage}
      >{this.createInitials()}</Avatar>

      logoutBtn = <CustomLogoutButton
        onClick={this.awsLogoutHandler}
      >
        Logout
      </CustomLogoutButton>
    } else {
      avatar = <img
        className={styles.profileImage}
        src={this.props.imageURL}
        alt="profile"
      />
      logoutBtn = <GoogleLogout
        clientId={clientId}
        buttonText="Logout"
        onLogoutSuccess={this.logout}>
      </GoogleLogout>
    }

    return (
      <>
        <Grid container className="mainWrapper">
          <Grid item md={9}>{userMarkets}</Grid>
          <Grid item md={3}>
            <div className={styles.profileBox}>
              {avatar}
              <div className={styles.logOut}>
                {logoutBtn}
              </div>
            </div>
          </Grid>
        </Grid>
        {
          <Dialog id="editSegment"
            open={this.state.openAlert}
            TransitionComponent={Transition}
            keepMounted
            onClose={(e) => this.handleAlert(e, false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{ classes: { root: classes.dialogPaper } }}
            className={classes.dialog}
          >
            <DialogContent className={classes.dialogContent}>

              <DialogContentText id="alert-dialog-slide-description">
                Note: You are about to change the current market {this.props.marketDisplayName}.
                Any unsaved changes will be lost.
                <br />
                <br />
                Are you sure?
              </DialogContentText>

            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={(e) => this.handleAlert(e, false)} className={classes.cancelButton}>
                Cancel
              </Button>
              <Button
                onClick={this.handleMarketChange}
                className={classes.button}
              >
                Ok
              </Button>
            </DialogActions>

          </Dialog>

        }
      </>
    );
  }
}
Profile.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  imageURL: state.app.imageURL,
  token: state.app.token,
  username: state.app.username,
  currentMarket: state.app.currentMarket,
  marketDisplayName: state.app.marketDisplayName


})
const mapDispatchToProps = (dispatch) => ({
  setLoader: (value) => dispatch({ type: SET_LOADER, value }),
  setMarketConfig: (key, value) => dispatch({ type: SET_MARKET_CONFIG, payload: { key, value } }),
  setToastFlag: (value) => dispatch({ type: SET_TOAST_FLAG, value }),
  setToast: (payload) => dispatch({ type: SET_TOAST, payload }),
  setMarketMaps: (key, value) => dispatch({ type: SET_DYNAMIC_MAPS, payload: { key, value } }),
});
export default compose(
  withStyles(style),
  connect(mapStateToProps, mapDispatchToProps)
)(Profile)