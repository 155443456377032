import { combineReducers } from 'redux';

import filterBarReducer from './filterBar/filterBar.reducer';
import segmentationReducer from './segmentation/segmentation.reducer';
import dashboardReducer from './dashboard/dashboard.reducers';
import mainAppReducer from './mainApp/mainApp.reducer';
import geoMapReducer from './geoMap/geoMap.reducer';
import summaryBarReducer from './summaryBar/summaryBar.reducer';
import productAutoCompleteReducer from './productAutoComplete/productAutoComplete.reducer';
import categoryReducer from './category/category.reducer';

export default combineReducers({
    app: mainAppReducer,
    dashboard: dashboardReducer,
    segmentation: segmentationReducer,
    filterBar: filterBarReducer,
    geoMap: geoMapReducer,
    summaryBar: summaryBarReducer,
    productAutoComplete: productAutoCompleteReducer,
    category: categoryReducer,
});