import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const Toast = ({ open, autoHideDuration, handleClose, message, anchorOrigin, severity }) => {
    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={autoHideDuration ? autoHideDuration : 6000}
                onClose={() => handleClose(false)}
                message={message}
                anchorOrigin={anchorOrigin ? anchorOrigin : { vertical: "top", horizontal: "center" }}
            >
                <Alert variant="filled" onClose={() => handleClose(false)} severity={severity ? severity : "success"} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default Toast;