import React, { Component, Suspense } from "react";
import { Tabs, Tab, Typography, Box, Grid, CircularProgress } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import { connect } from "react-redux";
import {
  // INDEX_0,
  // INDEX_1,
  // INDEX_2,
  // INDEX_3,
  SAVE_TAB_INDEX,
} from "../../redux/dashboard/dashboard.actions";
import { RUN_META_DATA } from "../../redux/filterBar/filterBar.actions";

import Header from "../../components/Header/Header";
import Profile from "../../components/Profile/Profile";
// import Segmentation from "../Segmentation/Segmentation";
// import SavedSegments from "../SavedSegments/SavedSegments";
// import Campaigns from "../Campaigns/Campaigns";
// import DataMgmt from "../DataMgmt/DataMgmt";

import styles from "./Dashboard.module.css";
import Toast from "../../components/Toast/Toast";
import { SET_TOAST_FLAG } from "../../redux/mainApp/mainApp.actions";
//Implementing lazy loading so that application bundles in chunks and load faster
const DataMgmt = React.lazy(() => import('../DataMgmt/DataMgmt').then(comp => comp))
const Segmentation = React.lazy(() => import('../Segmentation/Segmentation').then(comp => comp))
const SavedSegments = React.lazy(() => import('../SavedSegments/SavedSegments').then(comp => comp))
const Campaigns = React.lazy(() => import('../Campaigns/Campaigns').then(comp => comp))

const theme = createMuiTheme({
  palette: {
    primary: {
      // McD light blue
      main: "#00A9E7",
    },
    secondary: {
      // McD red
      main: "#DA0006",
    },
  },
  //McD dark gray #292929 | McD light gray #6F6F6F
  typography: {
    h1: {
      fontSize: "48px",
      fontFamily: "Speedee_W_Rg",
      color: "#292929",
    },
    h2: {
      fontSize: "36px",
      fontFamily: "Speedee_W_Rg",
      color: "#292929",
    },
    h3: {
      fontSize: "24px",
      fontFamily: "Speedee_W_Rg",
      color: "#292929",
    },
    h4: {
      fontSize: "18px",
      fontFamily: "Speedee_W_Rg",
      color: "#292929",
    },
    body1: {
      fontSize: "16px",
      fontFamily: "Speedee_W_Rg",
      color: "#6F6F6F",
    },
    body2: {
      fontSize: "14px",
      fontFamily: "Speedee_W_Rg",
      color: "#6F6F6F",
    },
  },
});
// const config = require(process.env.REACT_APP_MARKET_CONFIG);
const tempArr = [];
let baseURL = "http://127.0.0.1:8080";
if (process.env.REACT_APP_BASE_URL === "oneapp") {
  // baseURL = config.backendBaseURL;
  if (process.env.REACT_APP_BACKEND_BASE_URL) {
    baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  }
}

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <Typography
      className={styles.tabPanel}
      hidden={value !== index}
      component="div"
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {<Box>{children}</Box>}
    </Typography>
  );
}

class Dashboard extends Component {
  // dynamicConfig = JSON.parse(sessionStorage.getItem("marketConfig"))
  tabs = ["Data Management", "Segmentation", "Saved Segments", "Campaigns"]

  handleChange = (event, newValue) => {
    // if (newValue === 0) {
    //   this.props.index0();
    //   this.props.runMetaDataFunction(false);
    // } else if (newValue === 1) {
    //   this.props.index1();
    //   this.props.runMetaDataFunction(false);
    // } else if (newValue === 2) {
    //   this.props.index2();
    //   this.props.runMetaDataFunction(false);
    // } else if (newValue === 3) {
    //   this.props.index3();
    //   this.props.runMetaDataFunction(false);
    // }
    this.props.runMetaDataFunction(false);
    this.props.saveTabIndex(newValue);
    //fix leafly/plotly graphs rendering improperly after window resize on other tab
    window.dispatchEvent(new Event("resize"));
  };

  getTabPanelComponent = (tabname, tabIndex) => {
    let tabComponent = null;
    switch (tabname) {
      case "Data Management":
        tabComponent = (
          <DataMgmt active={this.props.value === tabIndex} baseURL={baseURL} />
        );
        break;
      case "Segmentation":
        tabComponent = (

          <Segmentation
            active={this.props.value === tabIndex}
            baseURL={baseURL}
            showFilterBar={true}
            showNameSegment={true}
            runFilterData={true}
            useTempTable={true}
          ></Segmentation>
        );
        break;
      case "Saved Segments":
        tabComponent = (
          <SavedSegments active={this.props.value === tabIndex} baseURL={baseURL} />
        );
        break;
      case "Campaigns":
        tabComponent = (
          <Campaigns active={this.props.value === tabIndex} baseURL={baseURL} />
        );
        break;
    }

    if (tempArr.indexOf(this.props.value) == -1) {
      tempArr.push(this.props.value)
    }
    return (
      <TabPanel
        key={`tabpanel-${tabIndex}`}
        value={this.props.value}
        index={tabIndex}
      >
        <Toast
          open={this.props.toastFlag}
          autoHideDuration={this.props.toast.duration}
          handleClose={this.props.setToastFlag}
          message={this.props.toast.msg}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          severity={this.props.toast.severity}
        />
        {this.props.pageLoader && (
          <CircularProgress
            className={styles.loadingSpinner}
            variant="indeterminate"
            color="secondary"
          />
        )}

        <Suspense fallback={<CircularProgress className={styles.loadingSpinner}
          variant="indeterminate"
          color="secondary" />}>
          {this.props.value === tabIndex ? tabComponent : tempArr.indexOf(tabIndex) != -1 && tabComponent}
        </Suspense >
        {/* {this.props.value === tabIndex ? tabComponent : tempArr.indexOf(tabIndex) != -1 && tabComponent} */}
      </TabPanel>
    );
  };
  render() {
    // console.log("this.dynamicConfig.tabPagesIncluded", this.dynamicConfig);
    return (
      <ThemeProvider theme={theme}>
        <div className={styles.fullPage}>
          <div className={styles.headerBox}>
            <Grid container direction="row">
              <Grid item md={3} sm={2} xs={2}>
                <Header />
              </Grid>
              <Grid item md={7} sm={8} xs={9}>
                <div position="static" className={styles.tabsBox}>
                  <Tabs
                    value={this.props.value}
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {this.tabs.map((tabname, i) => (
                      <Tab
                        key={`page-tab-${i}`}
                        label={<span className={styles.tabLabel}>{tabname}</span>}
                        disabled={!this.props.marketConfig || this.props.pageLoader}
                      />
                    ))}
                  </Tabs>
                </div>
              </Grid>
              <Grid item md={2} xs={1} sm={2}>
                <Profile baseURL={baseURL} handleChange={this.handleChange} />
              </Grid>
            </Grid>
          </div>

          {this.tabs.map((tabname, i) => this.getTabPanelComponent(tabname, i))}
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  value: state.dashboard.value,
  pageLoader: state.app.loader,
  toastFlag: state.app.toastFlag,
  toast: state.app.toast,
  marketConfig: state.app.marketConfig
});

const mapDispatchToProps = (dispatch) => ({
  // index0: () => dispatch({ type: INDEX_0 }),
  // index1: () => dispatch({ type: INDEX_1 }),
  // index2: () => dispatch({ type: INDEX_2 }),
  // index3: () => dispatch({ type: INDEX_3 }),
  saveTabIndex: (value) => dispatch({ type: SAVE_TAB_INDEX, value }),
  runMetaDataFunction: (condition) =>
    dispatch({ type: RUN_META_DATA, condition }),
  setToastFlag: (value) => dispatch({ type: SET_TOAST_FLAG, value }),

});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
