import React, { Component } from 'react';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login/Login';
import styles from './App.module.css';

import { connect } from 'react-redux';
import { SET_TOKEN, SET_GOOGLE_RESPONSE, SET_USERNAME, SET_IMAGE_URL, SET_GOOGLE_ID, SET_MARKET_CONFIG, SET_LOADER, SET_TOAST_FLAG, SET_TOAST } from './redux/mainApp/mainApp.actions'
import Cookies from 'universal-cookie';
import deData from "./common/deData.json"
import { remove_escape_char, decode_json_for_escapeChar } from "./utils/helper"
import { SET_INITIAL_SEGMENTATION_CONFIG } from "./redux/segmentation/segmentation.actions"
import { SET_INITIAL_FILTERBAR_CONFIG, SET_NEW_VALUE } from "./redux/filterBar/filterBar.actions"

import { SET_DYNAMIC_MAPS } from './redux/filterBar/filterBar.actions';
const cookies = new Cookies();
let baseURL = "http://127.0.0.1:8080";
if (process.env.REACT_APP_BASE_URL === "oneapp") {
  if (process.env.REACT_APP_BACKEND_BASE_URL) {
    baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  }
}
class App extends Component {
  // state = {
  //   isValidUserToken: false
  // }
  componentDidMount() {
    let authorized_markets = cookies.get("authorized_markets")
    let current_market = cookies.get("current_market")
    let selectedMarketExist = sessionStorage.getItem("selectedMarket")

    if (selectedMarketExist) {
      current_market = selectedMarketExist;
    }
    let activeToken = cookies.get("token")

    if (activeToken) {
      this.props.setToken(activeToken)
    }
    if (cookies.get("name")) {
      this.props.setUserName(cookies.get("name"))
    }
    if (authorized_markets) {
      let res = remove_escape_char(authorized_markets)
      this.props.setUserAuthorizedMarkets("authorized_markets", res);
      sessionStorage.setItem("authorized_markets", JSON.stringify(res))
    }
    if (current_market) {
      this.props.setMarketConfig("currentMarket", current_market);
      sessionStorage.setItem("current_market", current_market);
    }

    if (activeToken && current_market) {
      sessionStorage.removeItem("marketConfig")

      fetch(`${baseURL}/api/getUIMetaConfig/${current_market}`, {
        method: "GET",
        headers: { Accept: "application/json" },
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw { error: "Something went wrong", ok: response.ok, status: response.status, redirected: response.redirected, statusText: response.statusText, type: response.type, url: response.url }

          }
        })
        .then((response) => {
          console.log("Get current market Configurations", response);
          // this.props.setLoader(false)
          if (response.status === -1 || response.error) {
            this.props.setToastFlag(true)
            this.props.setToast({
              msg: response.message || response.error,
              duration: 4000,
              severity: "error"
            })
            // this.setState({ activeMarketChosen: previousMarket })
          } else {
            let reslt = decode_json_for_escapeChar(response);
            this.props.setMarketConfig("marketConfig", reslt)
            this.props.setMarketConfig("currentMarket", reslt.countryCode)
            this.props.setMarketConfig("marketDisplayName", reslt.market)
            this.props.setMarketMaps("geoLevel1Multiselect", reslt.geoLevel1List)
            if (response.geoLevel2List) {
              this.props.setMarketMaps("geoLevel2Multiselect", reslt.geoLevel2List)
            }
            let filterBarPayload = {
              valueSegment: reslt.cvsSegments,
              churnSlider: [reslt.marksChurn[0].value, reslt.marksChurn[1].value],
              ltvSlider: [reslt.marksLtv[0].value, reslt.marksLtv[1].value],
              ageGroupSegments: reslt.ageGroupSegments,
              geoLevel1List: reslt.geoLevel1List,
              geoLevel2List: reslt.geoLevel2List,
            }
            this.props.setInitialSegmentationConfig(reslt.cvsSegments);

            this.props.setInitialFilterBarConfig(filterBarPayload);
            // this.props.setNewValue(['valueSegment'], reslt.cvsSegments)
            sessionStorage.setItem("marketConfig", JSON.stringify(reslt))
            sessionStorage.setItem("current_market", reslt.countryCode);
            sessionStorage.removeItem("selectedMarket")

            // this.props.handleChange(e, 0)
          }

          //once it redirect to data management tab call the API to load that market data
        })
        .catch(error => {
          // this.props.setLoader(false)
          console.log("Changing market configuration error: ", error)
          this.props.setMarketConfig("marketConfig", { error: true })
          sessionStorage.setItem("marketConfig", null);
          this.props.setToastFlag(true)
          this.props.setToast({
            msg: error.error || "Something went wrong",
            duration: 4000,
            severity: "error"
          })
          sessionStorage.removeItem("selectedMarket")

        });
    }
    // sessionStorage.setItem("marketConfig", JSON.stringify(deData))
  }

  handleLogin = (response) => {
    if (process.env.REACT_APP_PLATFORM === "AWS") {
      if (response.token) {
        cookies.set('token', response.token)
        cookies.set('name', response.name)
        this.props.setUserName(response.name)
        this.props.setToken(response.token)
      }
      // this.setState({ isValidUserToken: true })
    } else {
      this.props.setGoogleResponse(response)
      this.props.setUserName(response.profileObj.givenName)
      this.props.setImageUrl(response.profileObj.imageUrl)
      this.props.setGoogleId(response.profileObj.googleId)
      this.setRefreshTimeout(response.tokenObj.expires_at);
      this.props.setToken(response.tokenId)
    }

  }

  setRefreshTimeout = (expiresAt) => {
    // Either 5 minutes before the deadline, or 5 minutes from now
    const oneMin = 60 * 1000;
    var refreshDeadline = Math.max(
      5 * oneMin,
      expiresAt - Date.now() - (5 * oneMin));
    console.log("Refreshing credentials in "
      + Math.floor(refreshDeadline / oneMin).toString()
      + " minutes");
    setTimeout(this.reloadAuthToken, refreshDeadline);
  }

  reloadAuthToken = () => {
    this.props.googleResponse.reloadAuthResponse().then(
      (authResponse) => {
        this.props.setToken(authResponse.id_token)
        this.setRefreshTimeout(authResponse.expires_at);
      },
      // fail handler.
      (failResponse) => {
        this.props.setToken("")
        console.log("Could not refresh token");
        console.log(failResponse);
      }
    );
  }


  render() {
    let component;
    if (process.env.REACT_APP_PLATFORM === "AWS") {
      // console.log("this.props.token", this.props.token);
      if (this.props.token) {
        component = <Dashboard />
      } else {
        component = <Login tokenCallback={this.handleLogin} />
      }

    } else {
      if (!this.props.token) {
        component = <Login tokenCallback={this.handleLogin} />
      }
      else {
        component = <Dashboard />
      }
    }


    return (
      <div className={styles.fullPage}>
        {component}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.app.token,
  googleResponse: state.app.googleResponse
})

const mapDispatchToProps = dispatch => ({
  setToken: (value) => dispatch({ type: SET_TOKEN, value }),
  setGoogleResponse: (value) => dispatch({ type: SET_GOOGLE_RESPONSE, value }),
  setUserName: (value) => dispatch({ type: SET_USERNAME, value }),
  setImageUrl: (value) => dispatch({ type: SET_IMAGE_URL, value }),
  setGoogleId: (value) => dispatch({ type: SET_GOOGLE_ID, value }),
  setUserAuthorizedMarkets: (key, value) => dispatch({ type: SET_MARKET_CONFIG, payload: { key, value } }),
  setLoader: (value) => dispatch({ type: SET_LOADER, value }),
  setToastFlag: (value) => dispatch({ type: SET_TOAST_FLAG, value }),
  setToast: (payload) => dispatch({ type: SET_TOAST, payload }),
  setMarketMaps: (key, value) => dispatch({ type: SET_DYNAMIC_MAPS, payload: { key, value } }),
  setInitialSegmentationConfig: (value) => dispatch({ type: SET_INITIAL_SEGMENTATION_CONFIG, value }),
  setInitialFilterBarConfig: (value) => dispatch({ type: SET_INITIAL_FILTERBAR_CONFIG, value }),
  setMarketConfig: (key, value) => dispatch({ type: SET_MARKET_CONFIG, payload: { key, value } }),
  setNewValue: (filterType, value) => dispatch({ type: SET_NEW_VALUE, filterType, value }),
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
