import * as actions from './filterBar.actions'
import dateFnsFormat from 'date-fns/format';
import { convertDateFormat } from '../../utils/helper';

// const config = require(process.env.REACT_APP_MARKET_CONFIG);
const conf = {
    cvsSegments: null,
    marksLtv: [1, 10],
    ageGroupSegments: null,
    geoLevel1List: [],
    geoLevel2List: [],

}
const config = JSON.parse(sessionStorage.getItem("marketConfig")) ? JSON.parse(sessionStorage.getItem("marketConfig")) : conf;

//functions for date
function getInitialDates(months = 0) {
    var d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    d.setMonth(d.getMonth() + months)
    return d;
}
function formatDate(date) {
    if (process.env.REACT_APP_PLATFORM === "AWS") {
        date = new Date(date.getUTCFullYear(), (date.getUTCMonth()), date.getUTCDate())
        return dateFnsFormat(date, "yyyy-MM-dd");
    } else {
        // return date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate() + config.timeStamp
        return convertDateFormat(date)
    }
}

const initialFilterData = {
    ltvUpper: "All",
    ltvLower: "All",
    churnLower: "All",
    churnUpper: "All",
    valueSegment: config.cvsSegments,
    begDate: formatDate(getInitialDates(-15)),
    endDate: formatDate(getInitialDates()),
    propensityProduct: "All",
    propensityLower: undefined,
    propensityUpper: undefined,
    categoryPropensityProduct: "All",
    categoryPropensityLower: undefined,
    categoryPropensityUpper: undefined,
    relevancyProduct: "All",
    relevancyLower: undefined,
    relevancyUpper: undefined,
    categoryRelevancyProduct: ["All", "All", "All", "All", "All"],
    categoryRelevancyLower: [],
    categoryRelevancyUpper: [],
    transactionOccasionProduct: ["All", "All", "All", "All", "All"],
    transactionOccasionLower: [],
    transactionOccasionUpper: [],
    frequencyFilter: "All",
    frequencyOperatorFilter: "All",
    frequencyOperator: "All",
    frequencyProduct: "All",
    frequencyLower: undefined,
    frequencyUpper: undefined,
    frequencyGreaterValue: "",
    frequencyLesserValue: "",
    frequencyVisitsFilter: "All",
    frequencyVisitsOperatorFilter: "All",
    frequencyVisitsOperator: "All",
    frequencyVisitsLower: "",
    frequencyVisitsUpper: "",
    frequencyVisitsGreaterValue: "",
    frequencyVisitsLesserValue: "",
    averageCheckFilter: "All",
    averageCheckOperatorFilter: "All",
    averageCheckOperator: "All",
    averageCheckLower: "",
    averageCheckUpper: "",
    averageCheckGreaterValue: "",
    averageCheckLesserValue: "",
    gender: "All",
    channels: "All",
    optIn: "All",
    dayPart: "All",
    ageGroups: "All",
    geoLevel1: "All",
    geoLevel2: "All",
    enableFilterByNewCustomer: false,
    enableFilterByLapsedCustomer: false,
    lapsedDays: "", //it should be from 1 to 365
    filterByNullCVSegment: false,
    gmaDelivery:"All"
}

const INITIAL_STATE = {
    initialFilterBarData: initialFilterData,
    filterBarData: initialFilterData,
    testSizeRecommendation: [],
    churnSlider: [config.marksLtv[0].value, config.marksLtv[1].value], //upper and lower selection of slider [as same will be decile now on]
    ltvSlider: [config.marksLtv[0].value, config.marksLtv[1].value], //upper and lower selection of slider
    propensityDisable: false, //will disable slider if loading
    relevancyDisable: false, //will disable slider if loading
    categoryPropensityDisable: false, //will disable slider if loading
    categoryRelevancyDisable: [false, false, false, false, false], //will disable slider if loading
    transactionOccasionDisable: [false, false, false, false, false], //will disable slider if loading
    frequencyDisable: false, //will disable slider if loading
    cvsMultiselect: [], //CVS selections of multiselect
    channelMultiselect: [], //channels selections of multiselect
    optInMultiselect: [], //optIn selections of multiselect
    dayPartMultiselect: [], //dayPart selections of multiselect
    ageGroupMultiselect: config.ageGroupSegments, // age group selections of multiselect
    geoLevel1Multiselect: config.geoLevel1List, //geoleve1 selections of multiselect
    geoLevel2Multiselect: config.geoLevel2List, //geolevel2 selections of multiselect
    selectAll1: true,
    selectAll2: true,
    geo1Error: false, //error if an empty response is returned
    geo2Error: false, //error if an empty response is returned
    averageCheckRangeError: [false, false, false, false],
    frequencyRangeError: [false, false, false, false],
    frequencyVisitsRangeError: [false, false, false, false],
    submittedFilterData: undefined,
    openPredictive: false,
    openDescriptive: false,
    openTransaction: false,
    openDemographics: false,
    openCustomerStatus: false,
    runMetaData: false,
    gmaDeliveryMultiselect:[]
}

const filterBarReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.SET_NEW_VALUE: {
            const { filterType, value } = action
            const filterBarData = { ...state.filterBarData }
            let modValue = null;
            if (value instanceof Array) {
                modValue = [...value];
            } else if (value instanceof Object) {
                modValue = { ...value };
            } else {
                modValue = value;
            }
            filterBarData[filterType] = modValue;
            return { ...state, filterBarData }
        }
        case actions.SET_SUBMITTED_DATA: {
            const { value } = action
            return { ...state, submittedFilterData: value }
        }
        case actions.SET_SLIDER: {
            const { sliderType, value } = action
            return { ...state, [sliderType]: value }
        }
        case actions.DISABLE_PRODUCT_SLIDER: {
            const { disableName, condition } = action
            return { ...state, [disableName]: condition }
        }
        case actions.SET_MULTISELECT_VALUE: {
            const { stateType, value } = action
            return { ...state, [stateType]: value }
        }
        case actions.GEO_ERROR: {
            const { geoError, condition } = action
            return { ...state, [geoError]: condition }
        }
        case actions.SELECT_ALL: {
            const { selectAll, condition } = action
            return { ...state, [selectAll]: condition }
        }
        case actions.SET_RANGE_ERROR: {
            const { errorType, condition } = action
            return { ...state, [errorType]: condition }
        }
        case actions.RESET_FILTERS: {
            let marktConf = JSON.parse(sessionStorage.getItem("marketConfig"))
            return { ...INITIAL_STATE, geoLevel1Multiselect: marktConf.geoLevel1List, geoLevel2Multiselect: marktConf.geoLevel2List, valueSegment: marktConf.cvsSegments, ltvSlider: [marktConf.marksLtv[0].value, marktConf.marksLtv[1].value], churnSlider: [marktConf.marksChurn[0].value, marktConf.marksChurn[1].value] }
        }
        case actions.SET_FILTER_BAR_DATA: {
            const { updatedData } = action
            return { ...state, filterBarData: updatedData }
        }
        case actions.SET_TEST_SIZE_RECOMMENDATION: {
            return { ...state, testSizeRecommendation: action.recommendationData }
        }
        case actions.RUN_META_DATA: {
            const { condition } = action
            return { ...state, runMetaData: condition }
        }
        case actions.OPEN_FILTER_DRAWER: {
            const { filterCategory, condition } = action
            return { ...state, [filterCategory]: condition }
        }
        case actions.SET_DYNAMIC_VALUE: {
            const { array: { channels, cvs, dayPart, gmaDelivery } } = action
            return { ...state, channelMultiselect: channels, dayPartMultiselect: dayPart, cvsMultiselect: cvs, gmaDeliveryMultiselect: gmaDelivery }
        }
        case actions.SET_DYNAMIC_MAPS: {
            const { payload } = action
            return { ...state, [payload.key]: payload.value }
        }
        case actions.SET_INITIAL_FILTERBAR_CONFIG: {
            const { value } = action
            return { ...state, ["churnSlider"]: value.churnSlider, ["ltvSlider"]: value.ltvSlider, ["geoLevel1Multiselect"]: value.geoLevel1List, ["geoLevel2Multiselect"]: value.geoLevel2List, ["ageGroupMultiselect"]: value.ageGroupSegments, ["initialFilterBarData"]: { ...state["initialFilterBarData"], valueSegment: value.valueSegment } }
        }
        default: {
            return state
        }

    }
}

export default filterBarReducer;
