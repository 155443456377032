// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Profile_profileBox__SGZxM {\n  flex:1 1;\n  display: flex;\n  position: relative;\n}\n\n.Profile_profileImage__136Mz {\n  border-radius: 50%;\n  height: 40px;\n  margin-top: auto;\n  margin-bottom: auto;\n  margin-right: 5px;\n}\n\n.Profile_userWrapper__260wy{\n    text-align: right;\n  }\n.Profile_logOut__aqsZS {\n  display: none;\n  position: absolute;\n  top: 40px;\n}\n\n.Profile_profileBox__SGZxM:hover .Profile_logOut__aqsZS {\n  display: block;\n  z-index: 999;\n}\n\n.Profile_username__IDPoA {\n  margin-top: auto;\n  margin-bottom: auto;\n}\n", "",{"version":3,"sources":["webpack://src/components/Profile/Profile.module.css"],"names":[],"mappings":"AAAA;EACE,QAAM;EACN,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;IACI,iBAAiB;EACnB;AACF;EACE,aAAa;EACb,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".profileBox {\n  flex:1;\n  display: flex;\n  position: relative;\n}\n\n.profileImage {\n  border-radius: 50%;\n  height: 40px;\n  margin-top: auto;\n  margin-bottom: auto;\n  margin-right: 5px;\n}\n\n.userWrapper{\n    text-align: right;\n  }\n.logOut {\n  display: none;\n  position: absolute;\n  top: 40px;\n}\n\n.profileBox:hover .logOut {\n  display: block;\n  z-index: 999;\n}\n\n.username {\n  margin-top: auto;\n  margin-bottom: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileBox": "Profile_profileBox__SGZxM",
	"profileImage": "Profile_profileImage__136Mz",
	"userWrapper": "Profile_userWrapper__260wy",
	"logOut": "Profile_logOut__aqsZS",
	"username": "Profile_username__IDPoA"
};
export default ___CSS_LOADER_EXPORT___;
