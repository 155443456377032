import React, { Component } from 'react';
import mcd from '../../static/golden_arches.png';
import styles from './Header.module.css';

import { connect } from 'react-redux';


class Header extends Component {

  render() {
    return (
      <div id="header" className={styles.header}>
        <div id="goldenArch" className={styles.logoBox}>
          <img src={mcd} alt="golden arches" className={styles.logo} />
        </div>
        <div id="title" className={styles.titleBox}>
          <p className={styles.title}>{process.env.REACT_APP_TITLE + " " + this.props.currentMarket} </p>
        </div>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  value: state.dashboard.value,
  currentMarket: state.app.marketDisplayName
})

export default connect(mapStateToProps)(Header);

