import React from 'react';
import NumericLabel from 'react-pretty-numbers';

export const addDaysToDate = (inputDate, days = 1) => {
  const date = new Date(inputDate);
  date.setDate(date.getDate() + days);
  return date;
};

/**
 * Split the date array and will return required date
 * @param {Array} dateArray 
 * @param {Number} index , 0 = start date , 1 = end date
 */
export const extractDate = (dateArray, index) => {
  if (dateArray) {
    const cdSplit = dateArray.split("-");
    if (cdSplit.length > 0) {
      const returnDate = cdSplit[index];
      return new Date(returnDate);
    }
  }
  return false;
};


export const dayDifference = (date1, date2) => {
  const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
  const timeDiff = Math.abs(date1.getTime() - date2.getTime());
  return Math.ceil(timeDiff / MILLISECONDS_PER_DAY);
}

// ttl = in hours
export const saveInLocalStorage = (key, data, ttl = 1) => {
  const milliseconds = ttl * 60 * 60 * 1000;
  const now = new Date();
  const item = {
    value: data,
    expiry: now.getTime() + milliseconds,
  }
  window.localStorage.setItem(key, JSON.stringify(item));
}

export const getLocalStorageItem = key => {
  const itemStr = localStorage.getItem(key)
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key)
    return null
  }
  return item.value;
}

export const convertDateFormat = inputDate => (
  new Date(inputDate.getTime() - inputDate.getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0]
);


export const convertDateToUTCFormat = timestamp => {
  // let date = new Date(timestamp);
  // let onlyDate = `${date.getUTCFullYear()}-${date.getUTCMonth()+1}-${date.getUTCDate()}`;
  // let onlyTime = `${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`;
  if (process.env.REACT_APP_PLATFORM === "AWS") {
    if (!Number.isInteger(timestamp)) {
      timestamp += " UTC";
    }
  }
  let utcReadableDate = new Date(timestamp).toUTCString();
  return utcReadableDate.split(' ').slice(0, 5).join(' ');
}

export const debounce = (fn, delay) => {
  let timeoutid;

  return (...args) => {
    if (timeoutid) {
      clearTimeout(timeoutid);
    }

    timeoutid = setTimeout(() => {
      fn(...args);
    }, delay);
  }
}

export const numberWithCommas = (x) => {
  const numberFormat = {
    precision: 0,
    justification: 'L',
  }

  return <NumericLabel params={numberFormat}>{x}</NumericLabel>;
  // return new Intl.NumberFormat('en-US').format(x);
}

//decode API json response with array/object values as strings
export const decode_json_for_escapeChar = (jsonObj) => {
  let tempJson = {}
  for (let [key, value] of Object.entries(jsonObj)) {
    if (typeof value === 'string') {
      if (value[0] == "{" || value[0] == "[") {
        // if (key == 'geoLevel1List' || key == 'geoLevel2List') {
        //   value = JSON.stringify(value)
        //   tempJson[key] = JSON.parse(value);
        // } else {
        tempJson[key] = JSON.parse(value);
        // }
      } else {
        tempJson[key] = value;
      }
    } else {
      tempJson[key] = value;
    }
  }
  return tempJson
}
//decode json data that contains escape char like {\"US\": {\"label\": \"US\"}\054
export const remove_escape_char = (val) => {
  val = val.replace(/\\"/g, '"');
  val = val.replace(/\\(\d{3})/g, function (match, octal) {
    return String.fromCharCode(parseInt(octal, 8));
  });
  return JSON.parse(val);
}