import * as actions from './productAutoComplete.actions';

const INITIAL_STATE = {
    multiProduct: ["All", "All", "All", "All", "All"],
    multiProductLowerValues: [],
    multiProductUpperValues: [],
}

const productAutoCompleteReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.SET_ARRAY_VALUES: {
            const { array, values } = action
            return {...state, [array]: values}
        }
        default: {
            return state
        }
    }

}

export default productAutoCompleteReducer;
