import * as actions from './geoMap.actions';

const INITIAL_STATE = {
    geoLevel1Checked: true,
    geoLevel2Checked: false
}

const geoMapReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.SET_GEO_LEVEL: {
            const { geoLevel, condition } = action
            return {...state, [geoLevel]: condition}
        }
        default: {
            return state
        }
    }

}

export default geoMapReducer;
