import * as actions from './summaryBar.actions';

const INITIAL_STATE = {
    segmentName: undefined,
    nameError: false,
    segmentSavedSuccess: undefined,
    refreshSegment: undefined
}

const summaryBarReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.SET_SEGMENT_NAME: {
            const { name } = action
            return {...state, segmentName: name}
        }
        case actions.SET_NAME_ERROR: {
            const { condition } = action
            return {...state, nameError: condition}
        }
        case actions.SET_SAVED_SUCCESS: {
            const { condition } = action
            return {...state, segmentSavedSuccess: condition}
        }
        case actions.SET_REFRESH_SEGMENT: {
            const { condition } = action
            return {...state, refreshSegment: condition}
        }
        default: {
            return state
        }
    }

}

export default summaryBarReducer;
