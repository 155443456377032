// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login_loginButton__26Nag {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding-top: 20vh;\n  align-items: center;\n  font-family: 'Speedee_W_Rg';\n}\n\n.Login_logo__15hBy {\n  height: 40px;\n}\n\n.Login_loginHeader__23b-A {\n  margin-top: 12px;\n  margin-bottom: 12px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/Login/Login.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,iBAAiB;EACjB,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".loginButton {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding-top: 20vh;\n  align-items: center;\n  font-family: 'Speedee_W_Rg';\n}\n\n.logo {\n  height: 40px;\n}\n\n.loginHeader {\n  margin-top: 12px;\n  margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginButton": "Login_loginButton__26Nag",
	"logo": "Login_logo__15hBy",
	"loginHeader": "Login_loginHeader__23b-A"
};
export default ___CSS_LOADER_EXPORT___;
