import * as actions from './segmentation.actions';
import dateFnsFormat from 'date-fns/format';
import { convertDateFormat } from '../../utils/helper';

// const config = require(process.env.REACT_APP_MARKET_CONFIG);
const conf = {
    cvsSegments: null

}
const config = JSON.parse(sessionStorage.getItem("marketConfig")) ? JSON.parse(sessionStorage.getItem("marketConfig")) : conf;

//functions for date
function getInitialDates(months = 0) {
    var d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    d.setMonth(d.getMonth() + months)
    return d;
}
function formatDate(date) {
    if (process.env.REACT_APP_PLATFORM === "AWS") {
        date = new Date(date.getUTCFullYear(), (date.getUTCMonth()), date.getUTCDate())
        return dateFnsFormat(date, "yyyy-MM-dd");
    } else {
        // return date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate() + config.timeStamp
        return convertDateFormat(date)
    }
}

const initialFilterData = {
    ltvUpper: "All",
    ltvLower: "All",
    churnLower: "All",
    churnUpper: "All",
    valueSegment: config.cvsSegments,
    begDate: formatDate(getInitialDates(-15)),
    endDate: formatDate(getInitialDates()),
    propensityProduct: "All",
    propensityLower: undefined,
    propensityUpper: undefined,
    categoryPropensityProduct: "All",
    categoryPropensityLower: undefined,
    categoryPropensityUpper: undefined,
    relevancyProduct: "All",
    relevancyLower: undefined,
    relevancyUpper: undefined,
    categoryRelevancyProduct: ["All", "All", "All", "All", "All"],
    categoryRelevancyLower: [],
    categoryRelevancyUpper: [],
    transactionOccasionProduct: ["All", "All", "All", "All", "All"],
    transactionOccasionLower: [],
    transactionOccasionUpper: [],
    frequencyFilter: "All",
    frequencyOperatorFilter: "All",
    frequencyOperator: "All",
    frequencyProduct: "All",
    frequencyLower: undefined,
    frequencyUpper: undefined,
    frequencyGreaterValue: "",
    frequencyLesserValue: "",
    frequencyVisitsFilter: "All",
    frequencyVisitsOperatorFilter: "All",
    frequencyVisitsOperator: "All",
    frequencyVisitsLower: "",
    frequencyVisitsUpper: "",
    frequencyVisitsGreaterValue: "",
    frequencyVisitsLesserValue: "",
    averageCheckFilter: "All",
    averageCheckOperatorFilter: "All",
    averageCheckOperator: "All",
    averageCheckLower: "",
    averageCheckUpper: "",
    averageCheckGreaterValue: "",
    averageCheckLesserValue: "",
    gender: "All",
    channels: "All",
    optIn: "All",
    dayPart: "All",
    ageGroups: "All",
    geoLevel1: "All",
    geoLevel2: "All",
    enableFilterByNewCustomer: false,
    enableFilterByLapsedCustomer: false,
    lapsedDays: "", //it should be from 1 to 365
    filterByNullCVSegment: false,
    gmaDelivery: "All",
}

const INITIAL_STATE = {
    initialFilterData: initialFilterData,
    filterData: initialFilterData,
    metaData: initialFilterData,
    tempTable: "loading",
    tempViewSegmentTable: "loading"
}

const segmentationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.RUN_FILTERS: {
            const { updatedData } = action
            return { ...state, filterData: updatedData }
        }
        case actions.SET_METADATA: {
            const { data } = action
            return { ...state, metaData: data }
        }
        case actions.SET_TEMP_TABLE: {
            const { updatedBool } = action
            return { ...state, tempTable: updatedBool }
        }
        case actions.SET_TEMP_VIEW_SEGMENT_TABLE: {
            const { updatedBool } = action
            return { ...state, tempViewSegmentTable: updatedBool }
        }
        case actions.SET_FILTER_VALUE: {
            const { filterType, value } = action
            const filterData = { ...state.filterData }
            filterData[filterType] = value
            return { ...state, filterData }
        }
        case actions.SET_INITIAL_SEGMENTATION_CONFIG: {
            const { value } = action
            return { ...state, initialFilterData: { ...state["initialFilterData"], valueSegment: value } }

        }
        default: {
            return state
        }
    }

}

export default segmentationReducer;
