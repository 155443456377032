import React, { Component } from 'react';
import styles from './Login.module.css';
import { GoogleLogin } from 'react-google-login';
import mcd from '../../static/golden_arches.png';
import { TextField, Button } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
// const config = require(process.env.REACT_APP_MARKET_CONFIG);

const CustomLoginButton = withStyles({
  root: {
    backgroundColor: 'rgb(255, 255, 255)',
    display: 'inline - flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px',
    // padding: 0,
    borderRadius: '2px',
    border: '1px solid transparent',
    fontSize: '14px',
    fontWeight: 500
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

let clientId = "";
if (process.env.REACT_APP_CLIENT_ID) {
  clientId = process.env.REACT_APP_CLIENT_ID;
}
const useStyles = makeStyles((theme) => ({
  inputWidth: {
    marginTop: "4%",
  },
  submitBtn: {
    marginTop: "20px"
  }
}));

export const AwsLoginForm = (props) => {
  const classes = useStyles();
  const [fieldValues, setFieldValues] = useState({
    username: "",
    password: "",
  });

  const loginFieldHandler = (event) => {
    setFieldValues({
      ...fieldValues,
      [event.target.name]: event.target.value,
    });
  }
  const submitFormHandler = () => {
    // axios.post('http://serumflowapi.us-east-1.elasticbeanstalk.com/login', fieldValues)
    //   .then(res => {
    //   })
    fetch("http://de-dev-serum-api.eu-central-1.elasticbeanstalk.com/api/login",
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(fieldValues)
      })
      .then(response => {
        return response.json();
      }).then(response => {
        if (response.status === 401 || response.status === 403) {
          console.log(`error: ${response.message} with status code ${response.status}`)
        } else if (response.status === 200) {
          props.tokenCallback(response)
        }
      })
  }
  return <div>
    <form noValidate autoComplete="off">
      <TextField
        required
        autoFocus
        id="username"
        label="Username"
        value={fieldValues.username}
        onChange={(e) => loginFieldHandler(e)}
        name="username"
        className={classes.inputWidth}
      />
      <div>
        <TextField
          required
          type="password"
          id="loginPwd"
          label="Password"
          value={fieldValues.password}
          onChange={(e) => loginFieldHandler(e)}
          name="password"
          className={classes.inputWidth}
        />
      </div>
      <div>
        <Button
          onClick={() => submitFormHandler()}
          className={classes.submitBtn}
          disabled={fieldValues.password === '' || !fieldValues.password || fieldValues.username === '' || !fieldValues.username}
          variant="contained" color="primary">
          Submit
        </Button>
      </div>
    </form></div>
}
class Login extends Component {
  dynamicConfig = JSON.parse(sessionStorage.getItem("marketConfig"))
  state = {
    isLoginForm: false,
  }


  responseGoogle = (response) => {
    if (response) {
      this.props.tokenCallback(response);
    }
  }
  awsLoginHandler = () => {
    let backendBaseUrl = ""
    if (process.env.REACT_APP_BACKEND_BASE_URL) {
      backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
    }
    window.location.href = backendBaseUrl + "/api/login"
    // this.setState({ isLoginForm: true })
  }

  render() {
    let loginForm = null;
    if (process.env.REACT_APP_PLATFORM === "AWS") {
      if (this.state.isLoginForm) {
        loginForm = <AwsLoginForm tokenCallback={this.props.tokenCallback} />
      } else {
        loginForm = <CustomLoginButton
          onClick={this.awsLoginHandler}
        >
          Login
        </CustomLoginButton>
      }

    } else {
      loginForm = <GoogleLogin
        clientId={clientId}
        buttonText="Login"
        onSuccess={this.responseGoogle}
        onFailure={this.responseGoogle}
        isSignedIn={true}
      />

    }
    return (
      <div className={styles.loginButton}>
        <img src={mcd} alt="golden arches" className={styles.logo} />
        <h2 className={styles.loginHeader}>
          {/* Serum Flow {process.env.REACT_APP_MARKET} */}
          Serum Flow
        </h2>
        <div>
          {loginForm}
        </div>
      </div>
    );
  }
}

export default Login;
