import fetchIntercept from "fetch-intercept";
import store from '../redux/store';
/**
 * Below interceptor will only work for fetch API. 
 * For axios create axios interceptor or directly modify dataFetcher method used in src/common/utility.js 
 **/
//intercept all fetch requests
fetchIntercept.register({
    request: function (url, config) {
        // Modify the url or config here
        // converting truthy/falsy values to boolean using !!
        let isExist = !!url.indexOf("/api/");
        //checking if urls contains /api which only needs to add auth and market. For others,no need to add 
        if (isExist) {

            const state = store.getState();
            config.headers.Authorization = `Bearer ${state.app.token}`
            config.headers.market = state.app.currentMarket
        }
        return [url, config];
    },

    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: function (response) {
        // Modify the reponse object
        return response;
    },

    responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error);
    }
});