import * as actions from './mainApp.actions';

const INITIAL_STATE = {
    token: '',
    googleResponse: '',
    username: '',
    loader: false,
    currentMarket: "",
    marketDisplayName: "",
    marketConfig: null,
    authorized_markets: null,
    toastFlag: false,
    toast: {
        msg: "Success!",
        severity: "success",
        duration: 6000
    },
    imageURL: '',
    googleId: '',
}

const mainAppReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.SET_TOKEN: {
            const { value } = action
            return { ...state, token: value }
        }
        case actions.SET_GOOGLE_RESPONSE: {
            const { value } = action
            return { ...state, googleResponse: value }
        }
        case actions.SET_USERNAME: {
            const { value } = action
            return { ...state, username: value }
        }
        case actions.SET_IMAGE_URL: {
            const { value } = action
            return { ...state, imageURL: value }
        }
        case actions.SET_GOOGLE_ID: {
            const { value } = action
            return { ...state, googleId: value }
        }
        case actions.SET_LOADER: {
            const { value } = action
            return { ...state, loader: value }
        }
        case actions.SET_MARKET_CONFIG: {
            const { payload } = action
            return { ...state, [payload.key]: payload.value }
        }
        case actions.SET_TOAST_FLAG: {
            const { value } = action
            return { ...state, toastFlag: value }
        }
        case actions.SET_TOAST: {
            const { payload } = action
            return { ...state, toast: { ...payload } }
        }
        default: {
            return state
        }
    }

}

export default mainAppReducer;
