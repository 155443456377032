// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./fonts/Speedee_W_Rg.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "./fonts/Speedee_W_Rg.woff";
import ___CSS_LOADER_URL_IMPORT_2___ from "./fonts/Speedee_W_Bd.woff2";
import ___CSS_LOADER_URL_IMPORT_3___ from "./fonts/Speedee_W_Bd.woff";
import ___CSS_LOADER_URL_IMPORT_4___ from "./fonts/Speedee_W_Lt.woff2";
import ___CSS_LOADER_URL_IMPORT_5___ from "./fonts/Speedee_W_Lt.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'Speedee_W_Rg';\n  src: local('Speedee_W_Rg'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n}\n\n@font-face {\n  font-family: 'Speedee_W_Bd';\n  src: local('Speedee_W_Bd'), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2'),\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff');\n}\n\n@font-face {\n  font-family: 'Speedee_W_Lt';\n  src: local('Speedee_W_Lt'), url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff2'),\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('woff');\n}\n", "",{"version":3,"sources":["webpack://src/App.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B;wDAC6C;AAC/C;;AAEA;EACE,2BAA2B;EAC3B;wDAC6C;AAC/C;;AAEA;EACE,2BAA2B;EAC3B;wDAC6C;AAC/C","sourcesContent":["@font-face {\n  font-family: 'Speedee_W_Rg';\n  src: local('Speedee_W_Rg'), url(./fonts/Speedee_W_Rg.woff2) format('woff2'),\n  url(./fonts/Speedee_W_Rg.woff) format('woff');\n}\n\n@font-face {\n  font-family: 'Speedee_W_Bd';\n  src: local('Speedee_W_Bd'), url(./fonts/Speedee_W_Bd.woff2) format('woff2'),\n  url(./fonts/Speedee_W_Bd.woff) format('woff');\n}\n\n@font-face {\n  font-family: 'Speedee_W_Lt';\n  src: local('Speedee_W_Lt'), url(./fonts/Speedee_W_Lt.woff2) format('woff2'),\n  url(./fonts/Speedee_W_Lt.woff) format('woff');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
