export const SET_SLIDER = 'SET_SLIDER'
export const SET_NEW_VALUE = 'SET_NEW_VALUE'
export const SET_SUBMITTED_DATA = 'SET_SUBMITTED_DATA'
export const DISABLE_PRODUCT_SLIDER = 'DISABLE_PRODUCT_SLIDER'
export const GEO_ERROR = 'GEO_ERROR'
export const SELECT_ALL = 'SELECT_ALL'
export const SET_MULTISELECT_VALUE = 'SET_MULTISELECT_VALUE'
export const SET_RANGE_ERROR = 'SET_RANGE_ERROR'
export const RESET_FILTERS = 'RESET_FILTERS'
export const RUN_META_DATA = 'RUN_META_DATA'
export const SET_FILTER_BAR_DATA = 'SET_FILTER_BAR_DATA'
export const OPEN_FILTER_DRAWER = 'OPEN_FILTER_DRAWER'
export const SET_DYNAMIC_VALUE = 'SET_DYNAMIC_VALUE'
export const SET_TEST_SIZE_RECOMMENDATION = 'SET_TEST_SIZE_RECOMMENDATION'
export const SET_DYNAMIC_MAPS = 'SET_DYNAMIC_MAPS'
export const SET_INITIAL_FILTERBAR_CONFIG = 'SET_INITIAL_FILTERBAR_CONFIG'
