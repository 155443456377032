import * as actions from './dashboard.actions'

const INITIAL_STATE = {
    value: 0
}

const dashboardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type){
        case actions.INDEX_0: {
            return {...state, value: 0}
        }
        case actions.INDEX_1: {
            return {...state, value: 1}
        }
        case actions.INDEX_2: {
            return {...state, value: 2}
        }
        case actions.INDEX_3: {
            return {...state, value: 3}
        }
        case actions.SAVE_TAB_INDEX: {
            return {...state, value: action.value}
        }
        default: {
            return state
        }
    }
}

export default dashboardReducer;